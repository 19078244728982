<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.country') }}</label>
        <div class="input-group mb-3">
          <multiselect v-model="country"
                       :placeholder="$t('customers.country')"
                       label="name"
                       track-by="id"
                       :options="countries"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false"
                       @search-change="getCountries($event)"
                       :internal-search="false">
          </multiselect>
        </div>
        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('customers.country') }}.</span>
        <span v-if="validation && validation.country_code" class="fv-plugins-message-container invalid-feedback">
                {{ validation.country_code[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.city') }}</label>
        <div class="input-group mb-3">
          <multiselect v-model="city"
                       :placeholder="$t('customers.city')"
                       label="name" track-by="id"
                       :options="cities"
                       :multiple="false"
                       :taggable="true"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false">
          </multiselect>
          <div class="input-group-prepend">
            <a class="btn btn-primary" :href="data.country_code?'/settings/cities/'+data.country_code:'#'" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.city_id" class="fv-plugins-message-container invalid-feedback">
                {{ validation.city_id[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.state') }}</label>
        <input type="text" class="form-control" :class="validation && validation.state ? 'is-invalid' : ''" v-model="data.state"/>
        <span v-if="validation && validation.state" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.state[0] }}
                </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.postal_code') }}</label>
        <input type="text" class="form-control" :class="validation && validation.postal_code? 'is-invalid': ''" v-model="data.postal_code"/>
        <span v-if="validation && validation.postal_code" class="fv-plugins-message-container invalid-feedback">
                {{ validation.postal_code[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.address_1') }}</label>
        <input type="text" class="form-control" :class="  validation && validation.address_1 ? 'is-invalid' : ''" v-model="data.address_1"/>
        <span v-if="validation && validation.address_1" class="fv-plugins-message-container invalid-feedback">
                {{ validation.address_1[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.address_2') }}</label>
        <input type="text" class="form-control" :class=" validation && validation.address_2 ? 'is-invalid' : ''" v-model="data.address_2"/>
        <span v-if="validation && validation.address_2" class="fv-plugins-message-container invalid-feedback">
                {{ validation.address_2[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.type') }}</label>
        <select v-model="data.type" name="" id="" class="custom-select" :class=" validation && validation.type ? 'is-invalid' : ''">
          <option v-for="(row, index) in types" :value="row.id" :key="index">{{ row.name }}</option>
        </select>
        <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                {{ validation.type[0] }}
            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.is_active') }}</label>
        <div class="input-group">
          <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch></b-form-checkbox>
        </div>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('customers.is_default') }}</label>
        <div class="input-group">
          <b-form-checkbox size="lg" v-model="data.is_default" name="check-button" switch></b-form-checkbox>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-lg-6">
        <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
        <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "customer-address-info-modal",
  props: {
    customerId: null,
    hideModal: {type: Function},
    handlingData: {type: Function},
  },
  data() {
    return {
      mainRoute: 'customers/customers_address',

      mainRouteDependency: 'base/dependency',
      data: {
        customer_id: null,
        country_code: null,
        city_id: null,
        state: null,
        postal_code: null,
        address_1: null,
        address_2: null,
        type: null,
        is_active: true,
        is_default: false,
      },

      countries: [],
      cities: [],
      validation: null,
      country: [],
      city: [],
      types: [
        {id: 1, name: this.$t('customer_types.billing')},
        {id: 2, name: this.$t('customer_types.shipping')},
        {id: 3, name: this.$t('customer_types.both')},
      ],


    };
  },

  watch: {

    country: function (val) {
      if (val && val.code2) {
        this.data.country_code = val.code2;
        this.getCities(val.code2);
      } else {
        this.data.country_code = null;
        this.cities = [];
      }
    },
    city: function (val) {
      if (val) {
        this.data.city_id = val.id;
      } else {
        this.data.city_id = null;
      }
    },
    'data.state': function (val) {
      if (val) {
        this.data.state = val.replace(/[\d]/g, "");
      }
    },

  },
  methods: {

    save() {
      this.create();
    },
    cancel() {
      this.$emit('hide-modal');
      this.afterSave();
    },
    finishedSave(su_object) {
      let _label = '';
      _label += su_object.country_name ? su_object.country_name : '';
      _label += su_object.city_name ? '-' + su_object.city_name : '';
      _label += su_object.address_1 ? '-' + su_object.address_1 : '';

      let _object =  {label: _label,...su_object};
      this.$emit('handling-data', _object);
    },
    create() {
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.afterSave();
        this.finishedSave(response.data.data);
        this.cancel();
        Vue.prototype.$postStatus = true;
      })
          .catch((error) => {
            Vue.prototype.$postStatus = true;
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      } else {
        this.countries = [];
      }
    },
    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },
    loadOptions() {
    },
    afterSave() {
      this.data = {
        customer_id: this.customerId,
        country_code: null,
        city_id: null,
        state: null,
        postal_code: null,
        address_1: null,
        address_2: null,
        is_active: false,
        is_default: false,
        type: null,
      };
      this.country = [];
      this.city = [];

    },
    getTypeName(id) {
      let _name = '';
      let _type = this.types.find(row => row.id == id);
      if (_type) {
        _name = _type.name;
      }
      return _name;
    },


    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {

        this.data.country_code = response.data.data.country_id;
        this.data.city_id = response.data.data.city_id;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
      });
    },
  },

  mounted() {
    this.defaultDataForUser();
    this.data.customer_id = this.customerId;
  },
}
</script>

<style scoped>

</style>